import {EcoType} from "../../types";
import {eco_bild} from "../../assets";

export const caseData: EcoType[] =
    [
        {title: "Зелений кооператив",
            full_title: "Створення екологічної спільноти",
            content: "Компанія \"Розбудова кооперативів\" успішно реалізувала проєкт \"Зелений кооператив\", що" +
                " забезпечив створення екологічної спільноти. Резиденти кооперативу заощаджують на комунальних послугах," +
                " активно взаємодіють між собою та сприяють збереженню довкілля. Цей проєкт став прикладом сталого " +
                "розвитку та популяризації екологічних ініціатив у будівництвіf.",
            pic: eco_bild,
            status: true
        },
        {title: "Спільнота комфорту",
            full_title: "Створення екологічної спільноти^",
            content: "Компанія \"Розбудова кооперативів\" успішно реалізувала проєкт \"Зелений кооператив\", що" +
                " забезпечив створення екологічної спільноти. Резиденти кооперативу заощаджують на комунальних послугах," +
                " активно взаємодіють між собою та сприяють збереженню довкілля. Цей проєкт став прикладом сталого " +
                "розвитку та популяризації екологічних ініціатив у будівництвіg.",
            pic: eco_bild,
            status: true
        },
        {title: "Екологічний Оазис",
            full_title: "Створення екологічної спільноти*",
            content: "Компанія \"Розбудова кооперативів\" успішно реалізувала проєкт \"Зелений кооператив\", що" +
                " забезпечив створення екологічної спільноти. Резиденти кооперативу заощаджують на комунальних послугах," +
                " активно взаємодіють між собою та сприяють збереженню довкілля. Цей проєкт став прикладом сталого " +
                "розвитку та популяризації екологічних ініціатив у будівництвіh.",
            pic: eco_bild,
            status: true
        },
        {title: "Беспечне майбутне",
            full_title: "Створення екологічної спільноти/",
            content: "Компанія \"Розбудова кооперативів\" успішно реалізувала проєкт \"Зелений кооператив\", що" +
                " забезпечив створення екологічної спільноти. Резиденти кооперативу заощаджують на комунальних послугах," +
                " активно взаємодіють між собою та сприяють збереженню довкілля. Цей проєкт став прикладом сталого " +
                "розвитку та популяризації екологічних ініціатив у будівництвіj.",
            pic: eco_bild,
            status: true
        },
        {title: "Сучасний вибір",
            full_title: "Створення екологічної спільноти.",
            content: "Компанія \"Розбудова кооперативів\" успішно реалізувала проєкт \"Зелений кооператив\", що" +
                " забезпечив створення екологічної спільноти. Резиденти кооперативу заощаджують на комунальних послугах," +
                " активно взаємодіють між собою та сприяють збереженню довкілля. Цей проєкт став прикладом сталого " +
                "розвитку та популяризації екологічних ініціатив у будівництвіk.",
            pic: eco_bild,
            status: true
        },
        // {title: "Беспечне майбутне",
        //     full_title: "Створення екологічної спільноти",
        //     content: "Компанія \"Розбудова кооперативів\" успішно реалізувала проєкт \"Зелений кооператив\", що" +
        //         " забезпечив створення екологічної спільноти. Резиденти кооперативу заощаджують на комунальних послугах," +
        //         " активно взаємодіють між собою та сприяють збереженню довкілля. Цей проєкт став прикладом сталого " +
        //         "розвитку та популяризації екологічних ініціатив у будівництві.",
        //     pic: eco_bild,
        //     status: true
        // },
        // {title: "Сучасний вибір",
        //     full_title: "Створення екологічної спільноти",
        //     content: "Компанія \"Розбудова кооперативів\" успішно реалізувала проєкт \"Зелений кооператив\", що" +
        //         " забезпечив створення екологічної спільноти. Резиденти кооперативу заощаджують на комунальних послугах," +
        //         " активно взаємодіють між собою та сприяють збереженню довкілля. Цей проєкт став прикладом сталого " +
        //         "розвитку та популяризації екологічних ініціатив у будівництві.",
        //     pic: eco_bild,
        //     status: true
        // },
    ]